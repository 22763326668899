import { useEffect } from 'react'
import { Button, ButtonVariant } from '../Button'
import { Checkbox, CheckboxProps } from '../Checkbox'
import { ScrollArea } from '../ScrollArea'
import { H3 } from '../Typo/Header'

export type CheckboxOption = Pick<CheckboxProps, 'id' | 'label' | 'children'>

interface CheckboxGroupProps {
  header?: string
  options: CheckboxOption[]
  values: string[]
  onValuesChange: (values: string[]) => void
}

export function CheckboxGroup({
  header,
  options,
  values,
  onValuesChange,
}: CheckboxGroupProps) {
  const areAllSelected = options.every(option => values.includes(option.id))

  const selectAll = () => {
    onValuesChange(options.map(option => option.id))
  }

  const deselectAll = () => {
    onValuesChange([])
  }

  useEffect(selectAll, [])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-2 items-center">
        {header && <H3 isBold>{header}</H3>}

        <Button
          variant={ButtonVariant.Ghost}
          usePadding={false}
          className="p-1"
          onClick={areAllSelected ? deselectAll : selectAll}
        >
          {areAllSelected ? 'Deselect all' : 'Select all'}
        </Button>
      </div>

      <ScrollArea type="always">
        <div className="max-h-36 flex flex-col gap-1">
          {options.map(option => (
            <Checkbox
              key={option.id}
              id={option.id}
              label={option.label}
              checked={values.includes(option.id)}
              onCheckedChange={checked => {
                if (checked) {
                  onValuesChange([...values, option.id])
                } else {
                  onValuesChange(values.filter(value => value !== option.id))
                }
              }}
            >
              {option.children}
            </Checkbox>
          ))}
        </div>
      </ScrollArea>
    </div>
  )
}
