import {
  Airdrop,
  AssetProps,
  BinanceLogo,
  BitcoinLogo,
  Blur,
  BscScanner,
  BaseScanner,
  Business,
  Buyback,
  ChevronDown16,
  ChevronDown24,
  ChevronRight16,
  ChevronRight24,
  ChevronUp16,
  ChevronUp24,
  CoinGeckoLogo,
  CoinGeckoMonoLogo,
  ConnectWallet,
  Discord,
  Duplicate16,
  Duplicate24,
  DuplicateFilled,
  ErrorAlert,
  EthScanner,
  EtherumLogo,
  EverdomeLogo,
  Exit24,
  FameLogo,
  GateLogo,
  General,
  HeroLogo,
  InfoAlert,
  Job,
  KakaoTalkLogo,
  KakaoTalkMonoLogo,
  KangaLogo,
  LifetimeAccess,
  LineLogo,
  LineMonoLogo,
  LinkOut16,
  LinkOut24,
  LinkedinLogo,
  LinkedinMonoLogo,
  Loading2,
  Lock,
  Mail,
  MarketingDS,
  MarketingOptimize,
  MarketingUP,
  MetaMaskLogo,
  MmproLogo,
  OKXLogo,
  OpenSeaLogo,
  PancakeLogo,
  PurchaseTenset,
  SatoshiIslandLogo,
  ScAuditAI,
  ScAuditModel,
  ScAudits,
  ScAuditsProcessing,
  ScDevelopment,
  ScDevelopmentCrosschain,
  ScDevelopmentTokens,
  ScDevelopmentVesting,
  Security,
  Success,
  SuccessAlert,
  TelegramAnnouncements,
  TelegramCommunity,
  TelegramLogo,
  TelegramMonoLogo,
  TelegramOutline,
  TensetLogo,
  TensetLogoWithLabel,
  TensetLogoWithLabelMono,
  Tglp,
  TglpLogotype,
  TransactionFee,
  TwitterLogo,
  TwitterMonoLogo,
  UiUx,
  Verification,
  Website,
  YearlyAccess,
  YoutubeLogo,
  YoutubeMonoLogo,
  MediumBudget,
  LargeBudget,
  AllGems,
  Voting,
  Selection,
  Refund,
  ZealyLogo,
  BitgetLogo,
  BnbChain,
  GemTglp,
  GemTpl,
  CoinWLogo,
  Tiers,
  TradableVesting,
  BurnTokens,
  BuyNftFromMarket,
  Supply,
  ReducedSupply,
  Lamborghini,
  Rolex,
  IPhone,
  AutomaticSwapback,
  ManualSwapback,
  NftLaunchpad,
  NftMarketplace,
  WhaleHunt,
  Clock,
  Gear,
  Done,
} from '../../assets/icons'

export enum IconName {
  OKX = 'okx',
  OpenSea = 'opensea',
  PancakeSwap = 'pancake',
  Gate = 'gate',
  Kanga = 'kanga',
  Tenset = 'tenset',
  TensetLogoMonoLabel = 'tensetlogolabelmono',
  TensetLogoLabel = 'tensetlogolabel',
  Duplicate24 = 'duplicate24',
  DuplicateFilled = 'duplicatefilled',
  LinkOut24 = 'linkout24',
  ChevronRight24 = 'chevronright24',
  ChevronUp24 = 'chevronup24',
  ChevronDown24 = 'chevrondown24',
  Exit24 = 'exit24',
  Duplicate16 = 'duplicate16',
  LinkOut16 = 'linkout16',
  ChevronRight16 = 'chevronright16',
  ChevronUp16 = 'chevronup16',
  ChevronDown16 = 'chevrondown16',
  MetaMask = 'metamask',
  SatoshiIsland = 'satoshiisland',
  Bitcoin = 'bitcon',
  Etherum = 'etherum',
  Everdome = 'everdome',
  Fame = 'fame',
  Hero = 'hero',
  Binance = 'binance',
  Mmpro = 'mmpro',
  Telegram = 'telegram',
  TelegramMono = 'telegram-mono',
  TelegramOutline = 'telegram-outline',
  TelegramAnnouncements = 'telegram-announcements',
  TelegramCommunity = 'telegram-community',
  Twitter = 'twitter',
  TwitterMono = 'twitter-mono',
  Linkedin = 'linkedin',
  LinkedinMono = 'linkedin-mono',
  CoinGecko = 'coingecko',
  CoinGeckoMono = 'coingecko-mono',
  Line = 'line',
  LineMono = 'line-mono',
  KakaoTalk = 'kakao-talk',
  KakaoTalkMono = 'kakao-talk-mono',
  Youtube = 'youtube',
  YoutubeMono = 'youtube-mono',
  Mail = 'mail',
  Loading2 = 'loading2',
  Airdrop = 'airdrop',
  Business = 'business',
  Buyback = 'buyback',
  General = 'general',
  Job = 'job',
  ScAudits = 'sc-audits',
  ScDevelopment = 'sc-development',
  Tglp = 'tglp',
  Discord = 'discord',
  BscScanner = 'bsc-scanner',
  BaseScanner = 'base-scanner',
  EthScanner = 'eth-scanner',
  MarketingDS = 'marketing-ds',
  MarketingOptimize = 'marketing-optimize',
  MarketingUP = 'marketing-up',
  ScAuditAI = 'sc-audit-ai',
  ScAuditModel = 'sc-audit-model',
  ScAuditsProcessing = 'sc-audit-processing',
  ScDevelopmentCrosschain = 'sc-development-crosschain',
  ScDevelopmentTokens = 'sc-development-tokens',
  ScDevelopmentVesting = 'sc-development-vesting',
  TransactionFee = 'transaction-fee',
  UiUx = 'ui-ux',
  LifetimeAccess = 'lifetime-access',
  YearlyAccess = 'yearly-access',
  ConnectWallet = 'connect-wallet',
  PurchaseTenset = 'purchase-tenset',
  Lock = 'lock',
  Security = 'security',
  TglpLogotype = 'tglp-logotype',
  Success = 'success',
  Verification = 'verification',
  Website = 'website',
  Blur = 'blur',
  SuccessAlert = 'success-alert',
  InfoAlert = 'info-alert',
  ErrorAlert = 'error-alert',
  MediumBudget = 'medium-budget',
  LargeBudget = 'large-budget',
  AllGems = 'all-gems',
  Voting = 'voting',
  Selection = 'selection',
  Refund = 'refund',
  ZealyLogo = 'zealy-logo',
  BitgetLogo = 'bitget',
  BnbChain = 'bnb-chain',
  GemTglp = 'gem-tglp',
  GemTpl = 'gem-tpl',
  CoinWLogo = 'coin-w',
  BurnTokens = 'burn-tokens',
  BuyNftFromMarket = 'buy-nft-from-market',
  Tiers = 'tiers',
  TradableVesting = 'tradable-vesting',
  Supply = 'supply',
  ReducedSupply = 'reduced-supply',
  Lamborghini = 'lamborghini',
  Rolex = 'rolex',
  IPhone = 'iphone',
  AutomaticSwapback = 'automatic-swapback',
  ManualSwapback = 'manual-swapback',
  WhaleHunt = 'whale-hunt',
  NftMarketplace = 'nft-marketplace',
  NftLaunchpad = 'nft-launchpad',
  Clock = 'clock',
  Gear = 'gear',
  Done = 'done',
}

export interface IconProps extends AssetProps {
  name?: IconName
}

export const IconNames = {
  [IconName.Twitter]: TwitterLogo,
  [IconName.TwitterMono]: TwitterMonoLogo,
  [IconName.Telegram]: TelegramLogo,
  [IconName.TelegramMono]: TelegramMonoLogo,
  [IconName.TelegramOutline]: TelegramOutline,
  [IconName.TelegramAnnouncements]: TelegramAnnouncements,
  [IconName.TelegramCommunity]: TelegramCommunity,
  [IconName.Linkedin]: LinkedinLogo,
  [IconName.LinkedinMono]: LinkedinMonoLogo,
  [IconName.CoinGecko]: CoinGeckoLogo,
  [IconName.CoinGeckoMono]: CoinGeckoMonoLogo,
  [IconName.Line]: LineLogo,
  [IconName.LineMono]: LineMonoLogo,
  [IconName.KakaoTalk]: KakaoTalkLogo,
  [IconName.KakaoTalkMono]: KakaoTalkMonoLogo,
  [IconName.Youtube]: YoutubeLogo,
  [IconName.YoutubeMono]: YoutubeMonoLogo,
  [IconName.OpenSea]: OpenSeaLogo,
  [IconName.OKX]: OKXLogo,
  [IconName.MetaMask]: MetaMaskLogo,
  [IconName.PancakeSwap]: PancakeLogo,
  [IconName.Gate]: GateLogo,
  [IconName.Kanga]: KangaLogo,
  [IconName.Tenset]: TensetLogo,
  [IconName.TensetLogoMonoLabel]: TensetLogoWithLabelMono,
  [IconName.TensetLogoLabel]: TensetLogoWithLabel,
  [IconName.Bitcoin]: BitcoinLogo,
  [IconName.Etherum]: EtherumLogo,
  [IconName.SatoshiIsland]: SatoshiIslandLogo,
  [IconName.Everdome]: EverdomeLogo,
  [IconName.Fame]: FameLogo,
  [IconName.Hero]: HeroLogo,
  [IconName.Binance]: BinanceLogo,
  [IconName.Mmpro]: MmproLogo,
  [IconName.Duplicate24]: Duplicate24,
  [IconName.DuplicateFilled]: DuplicateFilled,
  [IconName.LinkOut24]: LinkOut24,
  [IconName.ChevronRight24]: ChevronRight24,
  [IconName.ChevronUp24]: ChevronUp24,
  [IconName.ChevronDown24]: ChevronDown24,
  [IconName.Duplicate16]: Duplicate16,
  [IconName.LinkOut16]: LinkOut16,
  [IconName.ChevronRight16]: ChevronRight16,
  [IconName.ChevronUp16]: ChevronUp16,
  [IconName.ChevronDown16]: ChevronDown16,
  [IconName.Exit24]: Exit24,
  [IconName.Mail]: Mail,
  [IconName.Loading2]: Loading2,
  [IconName.Airdrop]: Airdrop,
  [IconName.Business]: Business,
  [IconName.Buyback]: Buyback,
  [IconName.General]: General,
  [IconName.Job]: Job,
  [IconName.ScAudits]: ScAudits,
  [IconName.ScDevelopment]: ScDevelopment,
  [IconName.Tglp]: Tglp,
  [IconName.Discord]: Discord,
  [IconName.BscScanner]: BscScanner,
  [IconName.BaseScanner]: BaseScanner,
  [IconName.EthScanner]: EthScanner,
  [IconName.MarketingDS]: MarketingDS,
  [IconName.MarketingOptimize]: MarketingOptimize,
  [IconName.MarketingUP]: MarketingUP,
  [IconName.ScAuditAI]: ScAuditAI,
  [IconName.ScAuditModel]: ScAuditModel,
  [IconName.ScAuditsProcessing]: ScAuditsProcessing,
  [IconName.ScDevelopmentCrosschain]: ScDevelopmentCrosschain,
  [IconName.ScDevelopmentTokens]: ScDevelopmentTokens,
  [IconName.ScDevelopmentVesting]: ScDevelopmentVesting,
  [IconName.TransactionFee]: TransactionFee,
  [IconName.UiUx]: UiUx,
  [IconName.LifetimeAccess]: LifetimeAccess,
  [IconName.YearlyAccess]: YearlyAccess,
  [IconName.PurchaseTenset]: PurchaseTenset,
  [IconName.ConnectWallet]: ConnectWallet,
  [IconName.Lock]: Lock,
  [IconName.Security]: Security,
  [IconName.TglpLogotype]: TglpLogotype,
  [IconName.Success]: Success,
  [IconName.Verification]: Verification,
  [IconName.Website]: Website,
  [IconName.Blur]: Blur,
  [IconName.SuccessAlert]: SuccessAlert,
  [IconName.InfoAlert]: InfoAlert,
  [IconName.ErrorAlert]: ErrorAlert,
  [IconName.MediumBudget]: MediumBudget,
  [IconName.LargeBudget]: LargeBudget,
  [IconName.AllGems]: AllGems,
  [IconName.Voting]: Voting,
  [IconName.Selection]: Selection,
  [IconName.Refund]: Refund,
  [IconName.ZealyLogo]: ZealyLogo,
  [IconName.BitgetLogo]: BitgetLogo,
  [IconName.BnbChain]: BnbChain,
  [IconName.GemTglp]: GemTglp,
  [IconName.GemTpl]: GemTpl,
  [IconName.CoinWLogo]: CoinWLogo,
  [IconName.BurnTokens]: BurnTokens,
  [IconName.BuyNftFromMarket]: BuyNftFromMarket,
  [IconName.Tiers]: Tiers,
  [IconName.TradableVesting]: TradableVesting,
  [IconName.Supply]: Supply,
  [IconName.ReducedSupply]: ReducedSupply,
  [IconName.Lamborghini]: Lamborghini,
  [IconName.Rolex]: Rolex,
  [IconName.IPhone]: IPhone,
  [IconName.AutomaticSwapback]: AutomaticSwapback,
  [IconName.ManualSwapback]: ManualSwapback,
  [IconName.WhaleHunt]: WhaleHunt,
  [IconName.NftMarketplace]: NftMarketplace,
  [IconName.NftLaunchpad]: NftLaunchpad,
  [IconName.Clock]: Clock,
  [IconName.Gear]: Gear,
  [IconName.Done]: Done,
}

export function Icon({
  name = IconName.Tenset,
  size,
  color,
  className,
  idPrefix,
}: IconProps) {
  const IconComponent = IconNames[name]

  return (
    <IconComponent
      size={size}
      color={color}
      className={className}
      idPrefix={idPrefix}
    />
  )
}
