import {
  bitgetIcon,
  metamaskIcon,
  okxIcon,
  walletConnectIcon,
} from '~/assets/svg'
import { Button, ButtonVariant, H3, Image, TextS } from '~/tenset-components'
import { useWallet, WalletProvider } from '~/tenset-web3'

interface SupportedProvider {
  title: string
  description: string
  icon: string
  type: WalletProvider
  isInstalled?: boolean
  downloadLink?: string
}

export function ConnectWalletProviders() {
  const supportedProviders: SupportedProvider[] = [
    {
      title: 'MetaMask',
      description: 'Connect to your MetaMask Wallet',
      icon: metamaskIcon,
      type: WalletProvider.MetaMask,
    },
    {
      title: 'WalletConnect',
      description: 'Scan with WalletConnect to connect',
      icon: walletConnectIcon,
      type: WalletProvider.WalletConnect,
    },
    {
      title: 'Bitget Wallet',
      description: 'Connect to your Bitget Wallet',
      icon: bitgetIcon,
      type: WalletProvider.Bitget,
      isInstalled: Boolean(window.bitkeep?.ethereum),
      downloadLink: 'https://web3.bitget.com/en/wallet-download',
    },
    {
      title: 'OKX',
      description: 'Connect to your OKX Wallet',
      icon: okxIcon,
      type: WalletProvider.Okx,
      isInstalled: Boolean(window.okexchain),
      downloadLink: 'https://www.okx.com/web3',
    },
  ]

  return (
    <div className="grid grid-cols-2 divide-x divide-y">
      {supportedProviders.map(provider => (
        <div
          key={provider.title}
          className="flex w-full flex-col items-center justify-center [&:nth-child(2)]:!border-t-0 [&:nth-child(3)]:!border-l-0"
        >
          <ConnectWalletProvider provider={provider} />
        </div>
      ))}
    </div>
  )
}

interface ConnectWalletProviderProps {
  provider: SupportedProvider
}

function ConnectWalletProvider({ provider }: ConnectWalletProviderProps) {
  const { wallet } = useWallet()

  const { title, description, icon, type, isInstalled, downloadLink } = provider

  const to = isInstalled === false ? downloadLink : undefined

  const onClick =
    isInstalled === false
      ? undefined
      : async () => {
          await wallet?.connect(type).catch(console.warn)
        }

  return (
    <Button
      variant={ButtonVariant.Ghost}
      className="flex w-full flex-col gap-2 py-3 md:py-6"
      to={to}
      onClick={onClick}
    >
      <H3 isBold>{title}</H3>

      <TextS>{description}</TextS>

      <div className="order-first">
        <Image src={icon} alt={title} width="50px" height="50px" />
      </div>
    </Button>
  )
}
