import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import clsx from 'clsx'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactElement,
} from 'react'

import { Icon, IconName } from '../Icon'
import { Text } from '../Typo/Text'

export interface CheckboxProps
  extends ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  id: string
  label: string | ReactElement
}

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    { className, id, label, checked, onCheckedChange, children, ...props },
    ref
  ) => (
    <div className="flex flex-row-reverse w-fit items-start gap-2">
      <div className="flex flex-col">
        <label htmlFor={id}>
          {typeof label === 'string' ? <Text>{label}</Text> : label}
        </label>

        <div>{children}</div>
      </div>

      <CheckboxPrimitive.Root
        ref={ref}
        className={clsx(
          'mt-0.5 peer h-5 w-5 shrink-0 rounded-sm border border-white ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-blue-500 data-[state=checked]:bg-blue-500 data-[state=checked]:text-white',
          className
        )}
        id={id}
        checked={checked}
        onCheckedChange={onCheckedChange}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={clsx('flex items-center justify-center text-current')}
        >
          <Icon name={IconName.Done} color="currentColor" size={10} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    </div>
  )
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
