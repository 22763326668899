[
  { "type": "constructor", "inputs": [], "stateMutability": "nonpayable" },
  { "type": "receive", "stateMutability": "payable" },
  {
    "type": "function",
    "name": "DEFAULT_ADMIN_ROLE",
    "inputs": [],
    "outputs": [{ "name": "", "type": "bytes32", "internalType": "bytes32" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "UPGRADE_INTERFACE_VERSION",
    "inputs": [],
    "outputs": [{ "name": "", "type": "string", "internalType": "string" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "approve",
    "inputs": [
      { "name": "to", "type": "address", "internalType": "address" },
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "balanceOf",
    "inputs": [
      { "name": "owner", "type": "address", "internalType": "address" }
    ],
    "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "burn",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "burnTokens",
    "inputs": [
      { "name": "amount", "type": "uint256", "internalType": "uint256" },
      { "name": "lockedTokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "getApproved",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [{ "name": "", "type": "address", "internalType": "address" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "getAttributes",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [
      {
        "name": "",
        "type": "tuple",
        "internalType": "struct ITGLPMembership.Attributes",
        "components": [
          {
            "name": "amountToWithdraw",
            "type": "uint256",
            "internalType": "uint256"
          },
          { "name": "affiliate", "type": "address", "internalType": "address" },
          {
            "name": "commissionNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "commissionDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "offerId", "type": "uint16", "internalType": "uint16" },
          { "name": "startTs", "type": "uint32", "internalType": "uint32" },
          { "name": "endTs", "type": "uint32", "internalType": "uint32" },
          {
            "name": "bonusNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "bonusDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "lockedTokenId",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "tierId", "type": "uint16", "internalType": "uint16" },
          {
            "name": "tokensForExtension",
            "type": "uint256",
            "internalType": "uint256"
          }
        ]
      }
    ],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "getNumberOfLocksPerTier",
    "inputs": [
      { "name": "tierId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "getRoleAdmin",
    "inputs": [
      { "name": "role", "type": "bytes32", "internalType": "bytes32" }
    ],
    "outputs": [{ "name": "", "type": "bytes32", "internalType": "bytes32" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "getTier",
    "inputs": [{ "name": "id", "type": "uint256", "internalType": "uint256" }],
    "outputs": [
      {
        "name": "",
        "type": "tuple",
        "internalType": "struct ITGLPMembership.Tier",
        "components": [
          { "name": "price", "type": "uint240", "internalType": "uint240" },
          { "name": "id", "type": "uint16", "internalType": "uint16" }
        ]
      }
    ],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "getToken",
    "inputs": [{ "name": "id", "type": "uint256", "internalType": "uint256" }],
    "outputs": [{ "name": "", "type": "address", "internalType": "address" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "grantRole",
    "inputs": [
      { "name": "role", "type": "bytes32", "internalType": "bytes32" },
      { "name": "account", "type": "address", "internalType": "address" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "hasRole",
    "inputs": [
      { "name": "role", "type": "bytes32", "internalType": "bytes32" },
      { "name": "account", "type": "address", "internalType": "address" }
    ],
    "outputs": [{ "name": "", "type": "bool", "internalType": "bool" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "increaseLockedAmount",
    "inputs": [
      { "name": "increaseBy", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "initialize",
    "inputs": [],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "isApprovedForAll",
    "inputs": [
      { "name": "owner", "type": "address", "internalType": "address" },
      { "name": "operator", "type": "address", "internalType": "address" }
    ],
    "outputs": [{ "name": "", "type": "bool", "internalType": "bool" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "lockedAmount",
    "inputs": [],
    "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "mint",
    "inputs": [
      { "name": "owner_", "type": "address", "internalType": "address" },
      {
        "name": "attributes",
        "type": "tuple",
        "internalType": "struct ITGLPMembership.Attributes",
        "components": [
          {
            "name": "amountToWithdraw",
            "type": "uint256",
            "internalType": "uint256"
          },
          { "name": "affiliate", "type": "address", "internalType": "address" },
          {
            "name": "commissionNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "commissionDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "offerId", "type": "uint16", "internalType": "uint16" },
          { "name": "startTs", "type": "uint32", "internalType": "uint32" },
          { "name": "endTs", "type": "uint32", "internalType": "uint32" },
          {
            "name": "bonusNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "bonusDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "lockedTokenId",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "tierId", "type": "uint16", "internalType": "uint16" },
          {
            "name": "tokensForExtension",
            "type": "uint256",
            "internalType": "uint256"
          }
        ]
      }
    ],
    "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "name",
    "inputs": [],
    "outputs": [{ "name": "", "type": "string", "internalType": "string" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "ownerOf",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [{ "name": "", "type": "address", "internalType": "address" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "proxiableUUID",
    "inputs": [],
    "outputs": [{ "name": "", "type": "bytes32", "internalType": "bytes32" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "reduceLockedAmount",
    "inputs": [
      { "name": "subtractor", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "renounceRole",
    "inputs": [
      { "name": "role", "type": "bytes32", "internalType": "bytes32" },
      {
        "name": "callerConfirmation",
        "type": "address",
        "internalType": "address"
      }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "revokeRole",
    "inputs": [
      { "name": "role", "type": "bytes32", "internalType": "bytes32" },
      { "name": "account", "type": "address", "internalType": "address" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "safeTransferFrom",
    "inputs": [
      { "name": "from", "type": "address", "internalType": "address" },
      { "name": "to", "type": "address", "internalType": "address" },
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "safeTransferFrom",
    "inputs": [
      { "name": "from", "type": "address", "internalType": "address" },
      { "name": "to", "type": "address", "internalType": "address" },
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" },
      { "name": "data", "type": "bytes", "internalType": "bytes" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "setApprovalForAll",
    "inputs": [
      { "name": "operator", "type": "address", "internalType": "address" },
      { "name": "approved", "type": "bool", "internalType": "bool" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "setNumberOfLocksPerTier",
    "inputs": [
      { "name": "tierId", "type": "uint256", "internalType": "uint256" },
      { "name": "numberOfLocks", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "setTier",
    "inputs": [
      {
        "name": "tier",
        "type": "tuple",
        "internalType": "struct ITGLPMembership.Tier",
        "components": [
          { "name": "price", "type": "uint240", "internalType": "uint240" },
          { "name": "id", "type": "uint16", "internalType": "uint16" }
        ]
      }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "setToken",
    "inputs": [
      { "name": "id", "type": "uint256", "internalType": "uint256" },
      { "name": "token", "type": "address", "internalType": "address" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "supportsInterface",
    "inputs": [
      { "name": "interfaceId", "type": "bytes4", "internalType": "bytes4" }
    ],
    "outputs": [{ "name": "", "type": "bool", "internalType": "bool" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "symbol",
    "inputs": [],
    "outputs": [{ "name": "", "type": "string", "internalType": "string" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "tokenByIndex",
    "inputs": [
      { "name": "index", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "tokenOfOwnerByIndex",
    "inputs": [
      { "name": "owner", "type": "address", "internalType": "address" },
      { "name": "index", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "tokenURI",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [{ "name": "", "type": "string", "internalType": "string" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "totalSupply",
    "inputs": [],
    "outputs": [{ "name": "", "type": "uint256", "internalType": "uint256" }],
    "stateMutability": "view"
  },
  {
    "type": "function",
    "name": "transferFrom",
    "inputs": [
      { "name": "from", "type": "address", "internalType": "address" },
      { "name": "to", "type": "address", "internalType": "address" },
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "updateAttributes",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" },
      {
        "name": "attributes",
        "type": "tuple",
        "internalType": "struct ITGLPMembership.Attributes",
        "components": [
          {
            "name": "amountToWithdraw",
            "type": "uint256",
            "internalType": "uint256"
          },
          { "name": "affiliate", "type": "address", "internalType": "address" },
          {
            "name": "commissionNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "commissionDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "offerId", "type": "uint16", "internalType": "uint16" },
          { "name": "startTs", "type": "uint32", "internalType": "uint32" },
          { "name": "endTs", "type": "uint32", "internalType": "uint32" },
          {
            "name": "bonusNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "bonusDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "lockedTokenId",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "tierId", "type": "uint16", "internalType": "uint16" },
          {
            "name": "tokensForExtension",
            "type": "uint256",
            "internalType": "uint256"
          }
        ]
      }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "upgradeToAndCall",
    "inputs": [
      {
        "name": "newImplementation",
        "type": "address",
        "internalType": "address"
      },
      { "name": "data", "type": "bytes", "internalType": "bytes" }
    ],
    "outputs": [],
    "stateMutability": "payable"
  },
  {
    "type": "function",
    "name": "withdrawCoin",
    "inputs": [
      { "name": "to", "type": "address", "internalType": "address payable" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "withdrawToken",
    "inputs": [
      { "name": "to", "type": "address", "internalType": "address" },
      {
        "name": "token_",
        "type": "address",
        "internalType": "contract IERC20"
      },
      { "name": "amount", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "function",
    "name": "withdrawTokens",
    "inputs": [
      { "name": "lockedTokenId", "type": "uint256", "internalType": "uint256" },
      { "name": "to", "type": "address", "internalType": "address" },
      { "name": "amount", "type": "uint256", "internalType": "uint256" }
    ],
    "outputs": [],
    "stateMutability": "nonpayable"
  },
  {
    "type": "event",
    "name": "Approval",
    "inputs": [
      {
        "name": "owner",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "approved",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "tokenId",
        "type": "uint256",
        "indexed": true,
        "internalType": "uint256"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "ApprovalForAll",
    "inputs": [
      {
        "name": "owner",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "operator",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "approved",
        "type": "bool",
        "indexed": false,
        "internalType": "bool"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "AttributesUpdated",
    "inputs": [
      {
        "name": "tokenId",
        "type": "uint256",
        "indexed": false,
        "internalType": "uint256"
      },
      {
        "name": "attributes",
        "type": "tuple",
        "indexed": false,
        "internalType": "struct ITGLPMembership.Attributes",
        "components": [
          {
            "name": "amountToWithdraw",
            "type": "uint256",
            "internalType": "uint256"
          },
          { "name": "affiliate", "type": "address", "internalType": "address" },
          {
            "name": "commissionNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "commissionDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "offerId", "type": "uint16", "internalType": "uint16" },
          { "name": "startTs", "type": "uint32", "internalType": "uint32" },
          { "name": "endTs", "type": "uint32", "internalType": "uint32" },
          {
            "name": "bonusNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "bonusDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "lockedTokenId",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "tierId", "type": "uint16", "internalType": "uint16" },
          {
            "name": "tokensForExtension",
            "type": "uint256",
            "internalType": "uint256"
          }
        ]
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "Initialized",
    "inputs": [
      {
        "name": "version",
        "type": "uint64",
        "indexed": false,
        "internalType": "uint64"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "LockedAmountUpdated",
    "inputs": [
      {
        "name": "lockedAmount",
        "type": "uint256",
        "indexed": false,
        "internalType": "uint256"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "ManagerUpdated",
    "inputs": [
      {
        "name": "manager",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "MembershipMinted",
    "inputs": [
      {
        "name": "owner",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "tokenId",
        "type": "uint256",
        "indexed": true,
        "internalType": "uint256"
      },
      {
        "name": "attributes",
        "type": "tuple",
        "indexed": false,
        "internalType": "struct ITGLPMembership.Attributes",
        "components": [
          {
            "name": "amountToWithdraw",
            "type": "uint256",
            "internalType": "uint256"
          },
          { "name": "affiliate", "type": "address", "internalType": "address" },
          {
            "name": "commissionNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "commissionDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "offerId", "type": "uint16", "internalType": "uint16" },
          { "name": "startTs", "type": "uint32", "internalType": "uint32" },
          { "name": "endTs", "type": "uint32", "internalType": "uint32" },
          {
            "name": "bonusNumerator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "bonusDenominator",
            "type": "uint16",
            "internalType": "uint16"
          },
          {
            "name": "lockedTokenId",
            "type": "uint16",
            "internalType": "uint16"
          },
          { "name": "tierId", "type": "uint16", "internalType": "uint16" },
          {
            "name": "tokensForExtension",
            "type": "uint256",
            "internalType": "uint256"
          }
        ]
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "RoleAdminChanged",
    "inputs": [
      {
        "name": "role",
        "type": "bytes32",
        "indexed": true,
        "internalType": "bytes32"
      },
      {
        "name": "previousAdminRole",
        "type": "bytes32",
        "indexed": true,
        "internalType": "bytes32"
      },
      {
        "name": "newAdminRole",
        "type": "bytes32",
        "indexed": true,
        "internalType": "bytes32"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "RoleGranted",
    "inputs": [
      {
        "name": "role",
        "type": "bytes32",
        "indexed": true,
        "internalType": "bytes32"
      },
      {
        "name": "account",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "sender",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "RoleRevoked",
    "inputs": [
      {
        "name": "role",
        "type": "bytes32",
        "indexed": true,
        "internalType": "bytes32"
      },
      {
        "name": "account",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "sender",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "TierUpdated",
    "inputs": [
      {
        "name": "tier",
        "type": "tuple",
        "indexed": false,
        "internalType": "struct ITGLPMembership.Tier",
        "components": [
          { "name": "price", "type": "uint240", "internalType": "uint240" },
          { "name": "id", "type": "uint16", "internalType": "uint16" }
        ]
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "TokenUpdated",
    "inputs": [
      {
        "name": "id",
        "type": "uint256",
        "indexed": false,
        "internalType": "uint256"
      },
      {
        "name": "token",
        "type": "address",
        "indexed": false,
        "internalType": "address"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "Transfer",
    "inputs": [
      {
        "name": "from",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "to",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      },
      {
        "name": "tokenId",
        "type": "uint256",
        "indexed": true,
        "internalType": "uint256"
      }
    ],
    "anonymous": false
  },
  {
    "type": "event",
    "name": "Upgraded",
    "inputs": [
      {
        "name": "implementation",
        "type": "address",
        "indexed": true,
        "internalType": "address"
      }
    ],
    "anonymous": false
  },
  { "type": "error", "name": "AccessControlBadConfirmation", "inputs": [] },
  {
    "type": "error",
    "name": "AccessControlUnauthorizedAccount",
    "inputs": [
      { "name": "account", "type": "address", "internalType": "address" },
      { "name": "neededRole", "type": "bytes32", "internalType": "bytes32" }
    ]
  },
  {
    "type": "error",
    "name": "AddressEmptyCode",
    "inputs": [
      { "name": "target", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "AddressInsufficientBalance",
    "inputs": [
      { "name": "account", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "ERC1967InvalidImplementation",
    "inputs": [
      { "name": "implementation", "type": "address", "internalType": "address" }
    ]
  },
  { "type": "error", "name": "ERC1967NonPayable", "inputs": [] },
  {
    "type": "error",
    "name": "ERC721EnumerableForbiddenBatchMint",
    "inputs": []
  },
  {
    "type": "error",
    "name": "ERC721IncorrectOwner",
    "inputs": [
      { "name": "sender", "type": "address", "internalType": "address" },
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" },
      { "name": "owner", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721InsufficientApproval",
    "inputs": [
      { "name": "operator", "type": "address", "internalType": "address" },
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721InvalidApprover",
    "inputs": [
      { "name": "approver", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721InvalidOperator",
    "inputs": [
      { "name": "operator", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721InvalidOwner",
    "inputs": [
      { "name": "owner", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721InvalidReceiver",
    "inputs": [
      { "name": "receiver", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721InvalidSender",
    "inputs": [
      { "name": "sender", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721NonexistentToken",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ]
  },
  {
    "type": "error",
    "name": "ERC721OutOfBoundsIndex",
    "inputs": [
      { "name": "owner", "type": "address", "internalType": "address" },
      { "name": "index", "type": "uint256", "internalType": "uint256" }
    ]
  },
  { "type": "error", "name": "FailedInnerCall", "inputs": [] },
  { "type": "error", "name": "InvalidArrayLength", "inputs": [] },
  { "type": "error", "name": "InvalidInitialization", "inputs": [] },
  { "type": "error", "name": "InvalidTierId", "inputs": [] },
  { "type": "error", "name": "InvalidTokenId", "inputs": [] },
  { "type": "error", "name": "NotInitializing", "inputs": [] },
  {
    "type": "error",
    "name": "SafeERC20FailedOperation",
    "inputs": [
      { "name": "token", "type": "address", "internalType": "address" }
    ]
  },
  {
    "type": "error",
    "name": "TransferNotAllowed",
    "inputs": [
      { "name": "tokenId", "type": "uint256", "internalType": "uint256" }
    ]
  },
  { "type": "error", "name": "UUPSUnauthorizedCallContext", "inputs": [] },
  {
    "type": "error",
    "name": "UUPSUnsupportedProxiableUUID",
    "inputs": [{ "name": "slot", "type": "bytes32", "internalType": "bytes32" }]
  },
  { "type": "error", "name": "UnacceptableValue", "inputs": [] },
  { "type": "error", "name": "WithdrawToZeroAddress", "inputs": [] }
]
